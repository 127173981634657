import React from 'react'
import { Row, Col } from 'react-bootstrap'

function MyEBook() {

  return (
    <>
      <Row>
        <Col>
          <h1>ebook title</h1>
          <hr/>

        </Col>
        <Col className='' md={8}>
          
        </Col>
      </Row>
    </>
  )
}

export default MyEBook