import { useState, useEffect } from 'react'
import { Card, Button, Row, Col, Spinner } from 'react-bootstrap'
import PaymentService from '../../services/PaymentService'
import EBookService from '../../services/EBookService'
import Helpers from '../store/Helpers'
import Notification from '../store/Notification'

function EBook(props) {
  const ebook = props.ebook;
  // const cart = useContext(CartContext);
  const { checkout } = PaymentService()
  const { hasEBook, getEBookUrl } = EBookService()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ userAsset, setUserAsset ] = useState(false)
  const [ error, setError ] = useState(false)
  const { goTo } = Helpers()
  const user = localStorage.getItem('user')

  useEffect(() => {
    if (user) {
      hasEBook(ebook.id).then((response) => {
        // Delete
        // console.log(response.message)
        setUserAsset(response.message)
      })
    }
    console.log('ebook', ebook)
    if (!ebook || ebook.detail === 'Not found.') {
      goTo('/ebooks/')
    }
  }, []);

  function goToCheckout(url)  {
    window.location = url
  }

  function proccessCheckout(id) {
    setIsLoading(true);
    // checkout(id, 'Ebook')
    checkout(id, 'Ebook').then(response => {
      if (response) {
        goToCheckout(response.url)
      }  else {
        setIsLoading(false)
        setError(true)
      }
    })
  }

  function openPDF(id) {
    getEBookUrl(id).then(response => {
      window.open(response.ebook_url, '_blank', 'noreferrer')
    }).catch(e => {
      console.log(e)
    })
    //window.open(url, '_blank', 'noreferrer')
  }

  const styles = {
    subtitle: {
      justifyContent: 'right',
    },
    priceTag: {
      justifyContent: 'right',
    }
  }
  
  return (
    <Card>
      <Card.Header>
        <Card.Img variant='top' src={ebook.image_url} className='cardImage' />
      </Card.Header>
      <Card.Body>
        <Card.Title>{ebook.name}</Card.Title>
        <Card.Subtitle style={styles.priceTag} className=''>{`USD ${ebook.price}`}</Card.Subtitle>
        <Card.Text style={styles.subtitle}>{ebook.short_description}</Card.Text>
        <Row>
        { error ?
          <Notification variant='danger' title='Oops!' description='There was an error trying to purchase that course. Plase try again later' /> :
          ''
        }
        { user ?
            userAsset  ?
              <Button onClick={() => openPDF(ebook.id)}>Open PDF</Button>
              :
              <Button onClick={() => proccessCheckout(ebook.id)}>
                { isLoading ? 
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> :
                  'Purchase'
                }
              </Button>
            :
            <Button onClick={() => goTo('/signin')}>Purchase</Button>
          }
          <Col sm="6" />
        </Row>
      </Card.Body>
    </Card>
  )
}

export default EBook;