import { Card, Nav } from 'react-bootstrap'

function EBookCard(props) {
  const ebook = props.ebook
  
  return (
    <Card>
      <Nav.Link href={`/ebook_detail/${ebook.id}`}>
        <Card.Img variant='top' src={ebook.image_url } className='cardImage' />
        <Card.Body>
          <Card.Title>{ebook.name}</Card.Title>
          <Card.Text>{ebook.short_description}</Card.Text>
        </Card.Body>
        <Card.Footer>
          <Card.Text>{`USD ${ebook.price}`}</Card.Text>
        </Card.Footer>
      </Nav.Link>
    </Card>
    )
  }
  
  export default EBookCard