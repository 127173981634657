import { createContext, useState } from "react";
import UserService from "../services/UserService";
import CourseService from "../services/CourseService";
import EBookService from "../services/EBookService";

export const UserContext = createContext({
  username: null,
  token: null,
  courses: null,
  ebooks: null,
  create: () => {},
  load: () => {},
  loadCourses: () => {},
  loadEBooks: () => {},
  getCourses: () => {},
  getEBooks: () => {},
});

export function UserProvider({children}) {
  const [username, setUsername] = useState(null);
  const [token, setToken] = useState(null);
  const [courses, setCourses] = useState(null);
  const [ebooks, setEbooks] = useState(null);
    
  function create(username_value, password_value) {
    const { register } = UserService();
    const res = register(username_value, password_value);

    return res ? load(username_value, password_value) : false;
  }
    
  function load(username_value, password_value) {
    const { login } = UserService();
    setToken(login(username_value, password_value));
  }

  function loadCourses() {
    const { getUserCourses } = CourseService();
    const fetchCourses = getUserCourses();

    return fetchCourses ? fetchCourses : null;
  }

  function loadEBooks() {
    const { getUserEBooks } = EBookService();
    const fetchEBooks = getUserEBooks();

    return fetchEBooks ? fetchEBooks : null;
  }

  function getCourses() {
    return courses ? courses : loadCourses();
  }

  function getEBooks() {
    return ebooks ? ebooks : loadEBooks();
  }
                
  const contextValue = {
    username: username,
    courses: courses,
    ebooks: ebooks,
    token: token,
    create: create,
    load: load,
    loadCourses,
    loadEBooks,
    getCourses,
    getEBooks
  }
              
  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
    )
}
              
export default UserProvider;
              
              
// CODE DOWN HERE

// Context (cart, addToCart, removeCart)
// Provider -> gives your React app access to all the things in your context