import React from 'react'
import { Row, Col, Image } from  'react-bootstrap'
import img1 from  '../assets/imgs/about-us-1.jpg'
import { FaWhatsapp, FaRegEnvelope } from 'react-icons/fa'

export default function contact() {
  const styles =  {
    img:  {
      maxHeight: '300px',
      width: 'auto',
      marginBottom: '20px',
    },
    info: {
      float: 'right',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
    }
  }

  return (
    <>
      <Row>
        <h1>
          About Us
        </h1>
        <p>
          At <b>BIH</b> we provide quality education with the aim of training you for what is coming.
          Blockchain technology has changed things and is the future
        </p>
        <hr/>
        <Col style={styles.title}>
          <Image src={img1} style={styles.img} className='center'/>
        </Col>
        <Col>
          <Row>
            <p>
              We create learning and professional development experiences for digital enthusiasts.
              Our focus has always been on the digital world: we create alternatives to the
              traditional and revolutionize education. From the beginning we have sought to
              provide effective modern education in the most accessible and rapid way possible.
              Our courses are taught by the best professionals, online and available 24/7
            </p>
          </Row>
          <Row>
          <p>
            Every individual has the potential to create change, whether in their life, their
            community, or the world. The transformative power of education is what unlocks that
            potential. Yet, access to high-quality education has been a privilege of the few. From
            “for some” to “for all.” By opening the classroom through online learning, BIH
            empowers millions of learners to unlock their potential and become changemakers.
            We train brilliant and creative minds and bring them closer to the crypto world.
          </p>
          </Row>
        </Col>
        <hr/>
        {/* <Col>
        </Col> */}
        </Row>
        <Row>
          <p>
            Welcome to the school of the future. <br/>
            Welcome to <b>BIH.</b>
          </p>
          <hr/>
        </Row>
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <h3 style={styles.title}>Contact us at</h3>
        </Col>
      </Row>    
      <Row>
        <Col>
          <p> <FaRegEnvelope icon="fa-regular fa-regenvelope"/> inquiries@blockchaininvestorhub.com </p>
        </Col>
        <Col>
          <p style={styles.info}> <FaWhatsapp icon="fa-regular fa-whatsapp"/> +1 (307) 240-2133 </p>
        </Col>
      </Row>
    </>
  )
}
