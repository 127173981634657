import React from 'react'
import { Row, Col, Card, Accordion } from  'react-bootstrap'

export default function FAQs() {
  return (
    <>
      <Row>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              Do I meet the requirements to participate in this program?
            </Accordion.Header>
            <Accordion.Body>
              ➢ If you are a student, a recent graduate, or an employee who wishes to obtain
              skills and knowledge in the field of blockchain and cryptocurrency, then you
              are encouraged to apply for this opportunity. There is a high demand for
              professionals with expertise in this area, and by gaining experience in the
              blockchain and crypto space, you will be better equipped to take advantage of
              job opportunities that arise
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              Will the certificate I earn be recognized by employers in the future?
            </Accordion.Header>
            <Accordion.Body>
              ➢ Certainly! Each certificate that you receive will come with a special
              identification number which you can use to showcase your
              achievements to potential employers. You can include this ID on your
              CV, LinkedIn profile and other professional platforms to highlight your
              skills, experiences, projects and the experts and companies you've
              worked with. The recruiters on our website will use this ID to verify
              your credentials and evaluate your suitability for their organization.
              The program has been developed in collaboration with prominent
              employers in the blockchain industry, who are seeking professionals
              with the specific skills taught in this course. Additionally, you will have
              the opportunity to network with recruiters throughout the duration of
              the program.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              I am currently studying or working, will this program interfere with my schedule?
            </Accordion.Header>
            <Accordion.Body>
              ➢ Not at all, we’ve designed the program to be flexible enough for you to
              adapt it to your own schedule.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              What are the methods for submitting payment for the program?
            </Accordion.Header>
            <Accordion.Body>
              ➢ If you are accepted into the program, you will receive an acceptance
              email containing all the necessary details and links. Our primary
              method of payment is online, which can be conveniently accessed
              through our website. In most cases, we offer a multi-installment
              payment option, which will also be explained in the acceptance email.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              Is this program conducted online?
            </Accordion.Header>
            <Accordion.Body>
              ➢ Yes, it is fully online for your own convenience
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
             What happens if I am unable to attend a session?
            </Accordion.Header>
            <Accordion.Body>
              ➢ No worries! But, in case you cancel in between the last 24 hours
              before the session, you will be charged 50% of the session. If not
              presented to the session without previous notification you will be
              charged 100% of the price of the session
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              What are the methods for submitting payment for the program?
            </Accordion.Header>
            <Accordion.Body>
              ➢ If you are accepted into the program, you will receive an acceptance email
              containing all the necessary details and links. Our primary method of payment
              is online, which can be conveniently accessed through our website. In most
              cases, we offer a multi-installment payment option, which will also be
              explained in the acceptance email.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              How do I unsubscribe?
            </Accordion.Header>
            <Accordion.Body>
              ➢ It must be requested by the student before the beginning of the third eBook of
              the course.  <br/>
              ➢ The quality assurance clause may only be used during the student's first
              course in Blockchain Investor Hub <br/>
              ➢ The return must be requested through our WhatsApp.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              What are the payment methods?
            </Accordion.Header>
            <Accordion.Body>
              ➢ Cash  <br/>
              ➢ Credit card <br/>
              ➢ Debit <br/>
              ➢ Transfer  <br/>
              ➢ Bank deposit in local currency  <br/>
              ➢ The options mentioned depend on the country you are in.  <br/>
              ➢ You can see the means of payment available on our website, selecting the
              Course you want, or by contacting our service center via WhatsApp.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </>
  )
}
