import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import CourseService from '../../services/CourseService';
import CourseCard from '../../components/product/CourseCard';
import LoadingSpinner from '../../components/store/LoadingSpinner';

function Courses() {
  const [courses, setCourses] = useState(null);
  const { getCourses } = CourseService();
  
  useEffect(() => {
    function loadCourses() {
      return getCourses().then(data  => {
        if (process.env.ENV_TYPE === 'development') {
          console.log('response', data );
        }
        setCourses(data);

        return data;
      }).catch(error => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(error);
        }
      });
    };

    loadCourses();
  }, []);

  if (process.env.ENV_TYPE === 'development') {
    console.log('Cursos:  ', courses);
  }

  const styles = {
    title: {
      marginBottom: '10px',
    }
  }
  
  return (
    <>
      <h1 styles={styles.title} align="center" className="p-3">Blockchain Courses</h1>
      <hr/>
        { courses !== null && courses !== undefined ?
          <Row xs={1} md={3} className="g-4">
            {courses.map((product) => (
              <Col align="center" key={product.id}>
                <CourseCard course={product} />
              </Col>
            ))}
          </Row>
          :
          <LoadingSpinner md={12} offset={0} />
        }
    </>
  )
}
    
export default Courses;