import { useNavigate } from 'react-router-dom'

export default function Helpers() {
  let navigate = useNavigate()
  
  function goTo(path) {
    navigate(path, {replace: true})
  }

  return { goTo }
}
