import { Navbar, Nav, Container } from 'react-bootstrap'
import AuthIcon from './auth/AuthIcon'
import Helpers from './store/Helpers'
import icon from '../assets/imgs/favicon.png'

function NavbarComponent() {
  const { goTo } = Helpers()

  const styles = {
    navContainer: {
      marginBottom: '20px',
    },
    nav: {
      //backgroundImage: `url(${icon})`,
      backgroundSize:  'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '150px',
    },
    navColapse: {
      paddingLeft: '5px',
      paddingBottom: '10px',
      zIndex: '2',
      backgroundImage: `url(${icon})`,
      backgroundSize: '200px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: 'white'
    }
  }
  
  return (
    <Container styles={styles.navContainer}>
      <Navbar expand="sm" style={styles.nav}>
        <Navbar.Brand onClick={() => goTo('/')}> BIH </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav" style={styles.navColapse}>
          <Nav className="me-auto">
            <Nav.Link onClick={() => goTo('courses/')}>Courses</Nav.Link>
            <Nav.Link onClick={() => goTo('ebooks/')}>E-Books</Nav.Link>
            <Nav.Link onClick={() => goTo('contact/')}>About Us</Nav.Link>
            <Nav.Link onClick={() => goTo('faqs/')}>FAQs</Nav.Link>
            <Nav.Link onClick={() => goTo('terms/')}>T & C</Nav.Link>
          </Nav>
          <Nav className='flex-end'>
            <AuthIcon />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
    )
  }
  
  export default NavbarComponent