import React, { useState } from 'react'
import { Alert }  from  'react-bootstrap'

export default function Notification(props) {
  const [show, setShow] = useState(true)
  const title = props.title
  const description = props.description
  const variant = props.variant

  return (
    show ? 
    <Alert variant={variant} /*onClose={() => setShow(false)} dismissible*/>
      <Alert.Heading>{title}</Alert.Heading>
        {description}
    </Alert>
    :
    <></>
  )
}
