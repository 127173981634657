import { Card, Nav } from 'react-bootstrap'

function CourseCard(props) {
  const course = props.course
  // console.log(course)

  const styles = {
    image: {
      maxHeight: '180px'
    },
    footer: {
      // backgroundColor: '#f6ebfa'
    }
  }
  
  return (
    <Card>
      <Nav.Link href={`/course_detail/${course.id}`}>
        <Card.Img variant='top' src={course.image_url} className='cardImage' style={styles.image}/>
        <Card.Body>
          <Card.Title>{course.name}</Card.Title>
          {/* <Card.Text>{course.short_description}</Card.Text> */}
        </Card.Body>
        <Card.Footer style={styles.footer}>
          <Card.Text>{`USD ${course.price}`}</Card.Text>
        </Card.Footer>
      </Nav.Link>
    </Card>
    )
  }
  
  export default CourseCard;