import React, { useState, useRef } from 'react'
import UserService from '../../services/UserService'
import { Button, Row, Col, Card, Form, FloatingLabel } from 'react-bootstrap'

export default function MyAccount() {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [status, setStatus] = useState(null)
  const username = useRef(null)
  const password = useRef(null)
  const password_repeat = useRef(null)
  const { update } = UserService()
  
  const localUser = localStorage.getItem('user')
  if (localUser && !user) {
    if (process.env.ENV_TYPE === 'development') {
      console.log(JSON.parse(localUser).name)
    }
    setUser(JSON.parse(localUser).name);
    // console.log('user: ', username.current.value);
    // username.current.value = user;
  }

  function handleSubmit(event) {
    event.preventDefault()

    password.current.value === password_repeat.current.value  ?
      update(password.current.value).then((response) => {
        setStatus('Contraseña actualziada correctamente!')
      }).catch(e  =>  {
        if (process.env.ENV_TYPE === 'development') {
          console.log(e);
        }
        setError('Hubo un error inesperado')
      })
      :
      setStatus('Las contraseñas no coinciden')
  }

  var styles = {
    label: {
      marginTop: '5px'
    }, 
    button: {
      marginTop: '5px',
      float: 'center',
    },
    inputContainer: {
      marginBottom: '10px'
    },
    goToRegister: {
      float: 'center',
    },
    gif: {
      //maxHeight:  '100%',
      maxWidth: '100%'
    },
    password: {
      marginBottom: '5px'
    }
  }

  return (
    <Card>
      <Row>
        <Col>
          {/* <Card.Img src={img1}/> */}
          {/* Agregar Foto */}

          {/* <video src={window.innerWidth > 600 ? img1 : img2} muted height='auto' width='auto' autoplay='true' loop style={styles.gif}>
            <source  type="video/mp4" />
          </video> */}
        </Col>
        <Col>
          <Card.Body className='d-flex flex-column'>
            <Card.Title>
              Your Account
            </Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Text className='text-muted error-email'>{status}</Form.Text>
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='floatingInput1' label={user} className=''>
                  <Form.Control ref={username} type='email' placeholder='Email' disabled className='disabled'/>
                </FloatingLabel>
                <Form.Text className='text-muted error-email'>{error}</Form.Text>
              </Form.Group>
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='floatingInput2' label='New Password' className='' style={styles.password}>
                  <Form.Control ref={password} type='password' placeholder='Password' />
                </FloatingLabel>
                <FloatingLabel controlId='floatingInput2' label='New Password' className='\'>
                  <Form.Control ref={password_repeat} type='password' placeholder='Password' />
                </FloatingLabel>
              </Form.Group>
              <Form.Group>
                <Button variant='primary' type='submit' style={styles.button} className='center'>
                  Update
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Col>
        <Col/>
      </Row>
    </Card>
  );
}
