import React, { useState } from 'react'
import { Card, Button, Row, Carousel, Container } from 'react-bootstrap'
import Helpers from '../components/store/Helpers'
import img1 from '../assets/imgs/carousel1.jpg'
import img2 from '../assets/imgs/carousel2.jpg'
import img4 from '../assets/imgs/carousel4.jpg'
// import { FcNext } from 'react-icons/fc'

function Landing() {
  const { goTo } = Helpers()
  var localUser = localStorage.getItem('user')
  const [user, setUser] = useState(localUser)

  const styles = {
    row: {
      marginTop: '50px',
    },
    carousel: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '15px',
      overflow: 'hidden'
    },
    carouselImage: {
      height: '60vh',
    },
    btn: {
      marginBottom: '3vh',
      marginTop: '2vh',
    },
    h1: {
      marginBottom: '1vh'
    },
    caption: {
      align: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '15vh',
    },
    card: {
      width: '18rem',
      backgroundColor: '#e1cbf5',
      opacity: '0.8'
    },
    indicators: {
      color: 'blueviolet'
    },
    carouselItem: {
      borderRadius: '15px',
      overflow: 'hidden'
    }
  }

  return (
    <>
      <Row style={styles.row}>
        <Carousel variant='dark' style={styles.carousel} >
          {/* <Carousel.NextIcon>
            <FcNext />
          </Carousel.NextIcon> */}
          <Carousel.Item style={styles.carouselItem}>
            <img
              className="d-block w-100 carouselImg"
              src={img1}
              alt="First slide"
              style={styles.carouselImage}
            />
            <Carousel.Caption style={styles.caption}>
              <Card style={styles.card}>
                <Card.Body>
                  <Card.Title>Get Started!</Card.Title>
                  <Card.Text> Here at BIH we offer more than 50 courses to get you  started </Card.Text>
                  <Button  color='' className='' onClick={() => goTo('/courses')}>
                    More
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item style={styles.carouselItem}>
            <img
              className="d-block w-100 carouselImg"
              src={img1}
              alt="First slide"
              style={styles.carouselImage}
            />
            {/* <Carousel.Caption>
            
              <h3>Encontra los cursos que ofrecemos!</h3>
              <Button  color='' className='' onClick={() => goTo('/courses')} style={styles.btn}>
                Ver Mas
              </Button>
            </Carousel.Caption> */}
            <Carousel.Caption style={styles.caption}>
              <Card style={styles.card}>
                <Card.Body>
                  <Card.Title>Get Started!</Card.Title>
                  <Card.Text> Here at BIH we offer more than 50 courses to get you  started </Card.Text>
                  <Button  color='' className='' onClick={() => goTo('/courses')}>
                    More
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item style={styles.carouselItem}>
            <img
              className="d-block w-100 carouselImg"
              src={img2}
              alt="Second slide"
              style={styles.carouselImage}
            />
            {/* <Carousel.Caption>
              <h3>Descarga +20 E-Books!</h3>
              <Button  color='' className='' onClick={() => goTo('/ebooks')} style={styles.btn}>
                Ver Mas
              </Button>
            </Carousel.Caption> */}
            <Carousel.Caption style={styles.caption}>
              <Card style={styles.card}>
                <Card.Body>
                  <Card.Title>Get Started!</Card.Title>
                  <Card.Text> Start training now in the crypto world </Card.Text>
                  <Button  color='' className='' onClick={() => goTo('/courses')}>
                    More
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item style={styles.carouselItem}>
            <img
              className="d-block w-100 carouselImg"
              src={img4}
              alt="Third slide"
              style={styles.carouselImage}
            />
            {/* <Carousel.Caption>
              <h3>No tenes Usuario?</h3>
              <Button  color='' className='' onClick={() => goTo('/signup')} style={styles.btn}>
                Registrate!
              </Button>
            </Carousel.Caption> */}
            <Carousel.Caption style={styles.caption}>
              <Card style={styles.card}>
                <Card.Body>
                <Card.Title>Get Started!</Card.Title>
                  <Card.Text> Here at BIH we offer a variety of courses and ebooks to get you started </Card.Text>
                  <Button  color='' className='' onClick={() => goTo('/courses')}>
                    More
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Row>
      {/* <Row style={styles.row}>
        <Col className='md-8 offset-5'>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="holder.js/100px180" />
            <Card.Body>
              <Card.Title>Empeza ya a capacitarte!</Card.Title>
              <Card.Text>
                En CoFi tenemos mas de 50 cursos para que empieces a ya mismo
              </Card.Text>
              <Button  color='' className='' onClick={() => goTo()}>
                Ver Mas
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

                                      {/* SECCION REGISTRO */}
      {!user ?
        <Row style={styles.row}>
          <h1 align="center" style={styles.h1}> Not a member? </h1>
          <p align="center"> Sign up for free! </p>
          <div align="center">
            <Button  color='' className='' onClick={() => goTo('/signup')}>
              Sign up!
            </Button>
          </div>
        </Row>
      : '' }

                                      {/* SECCION REGISTRO */}

      <Row style={styles.row}>
        <h1 align="center" style={styles.h1}> About Us </h1>
        <p align="center"> Here at BIH we offer more than 50 courses to get you  started </p>
        <p align="center"> and more than 20 E-Books to start training wherever you want!</p>
      </Row>

      {/* <Row>
        <img src={banner1} height='auto' width={'auto'}/>
      </Row> */}

    </>
  )
};

export default Landing;
