import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import EBookService from '../../services/EBookService'
import EBook from '../../components/product/EBook'
import LoadingSpinner from '../../components/store/LoadingSpinner'
import Helpers from '../../components/store/Helpers'

function EBookDetail() {
  const [loading, setLoading] = useState(true)
  const [ebook, setEBook] = useState(null)
  const { getEBook } = EBookService()
  const { goTo } = Helpers()
  const id = useParams().productId
  
  useEffect(() => {
    // if (!id) {
    //   goTo('/ebooks/')
    // }

    function loadEBook() {
      return getEBook(id).then(data  => {
        if (process.env.ENV_TYPE === 'development') {
          console.log('ebook: ', data)
        }
        setEBook(data);
        return data;
      }).catch(error => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(error);
        }
      });
    };

    loadEBook()
    setLoading(false)
  }, []);

  const styles = {
    title: {
      marginBottom: '10px',
    },
    spinner: {
      justifyContent: 'center',
      align: 'center',
      display: 'flex'
    }
  }

  const longDesc = ebook ? ebook.long_description ? ebook.long_description.split('\n') : '' : ''
  let counter = 0
  //console.log('Ebook desc:', ebook.long_description)
  return (
    !loading && ebook ?
      <>
        <hr/>
        <Row>
          <Col className='md-8'>
            { longDesc.map((row) => {
                counter++
                return (
                  <Row key={counter}>
                    <p>{row}</p>
                  </Row>
                )
              })
            }
          </Col>
          <Col mg={2} >
            <EBook ebook={ebook} />
          </Col>
        </Row>
      </>
      :
      <LoadingSpinner md={12} offset={0} />
  );
}
  
export default EBookDetail;