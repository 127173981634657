import Helpers from '../components/store/Helpers'

export default function EBookService() {
  const api_url = process.env.REACT_APP_API_URL_PROD
  const user = localStorage.getItem('user')
  const { goTo } = Helpers()
  let token = null

  async function getEBooks() {
    const path = 'ebook/'
    const url = api_url+path

    return fetch(url)
    .then((response) => {
      return response;
    }).then((data) => {
      return data.json()
    }).catch(error => {
      if (process.env.ENV_TYPE === 'development') {
        console.log('Error:  ', error)
      }
      return null;
    });
  };
  
  async function getEBook(id) {
    const path = 'ebook/'
    const url = api_url+path+id

    return fetch(url)
    .then((response) => {
      return response
    }).then((data) => {
      return data.json();
    }).catch(error => {
      return error
    });
  };

  async function getUserEBooks() {
    const path = 'user-ebook/'
    const url = api_url+path
    if (process.env.ENV_TYPE === 'development') {
      console.log('User: ', localStorage.getItem('user'));
    }
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      return response.json();
    });
  };
  
  async function getCourseEBooks(id) {
    const path = `ebook_course/${id}/`
    const url = api_url+path;
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      //console.log(response.json())
      return response.json();
    }).catch((e) => {
      console.log(e)
      return null;
    });
  }
  
  async function getEBookUrl(id) {
    const path = `get_ebook_url/${id}/`
    const url = api_url+path
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      //console.log(response.json())
      return response.json();
    }).catch((e) => {
      console.log(e)
      return null;
    });
  }

  async function hasEBook(id) {
    const path = `user_asset/ebook/${id}/`;
    const url = api_url+path;
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      return response.json()
    }).catch((e) => {
      console.log(e)
      return null
    });
  }

  return { getEBooks, getUserEBooks, getEBook, getCourseEBooks, getEBookUrl, hasEBook };
}
