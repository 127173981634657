import React, { useState, useEffect } from 'react'
import { Col, Row, Card, Nav } from 'react-bootstrap'
import CourseService from '../../services/CourseService'
import { useSearchParams } from 'react-router-dom'
import Notification from '../../components/store/Notification'
import LoadingSpinner from '../../components/store/LoadingSpinner'
import NoProduct from '../../components/store/NoProduct'
import Helpers from '../../components/store/Helpers'

export default function MyCourses() {
  const [courses, setCourses] = useState(null)
  const [loading, setLoading] = useState(true)
  const { getUserCourses } = CourseService()
  const { goTo } = Helpers()
  const [searchParams, setSearchParams] = useSearchParams({success: false})
  const confirmation = searchParams.get('success')
  //console.log(confirmation)
  
  useEffect(() => {
    function loadCourses() {
      return getUserCourses().then(data  => {
        if (process.env.ENV_TYPE === 'development') {
          console.log('response', data )
        }
        if (data) {
          setCourses(data)
        }
        
        return data
      }).catch(error => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(error)
        }
        goTo('/')
      })
    }
    
    loadCourses()
    setLoading(false)
    // console.log(courses)
  }, [])

  const styles = {
    cardImage: {
      maxHeight: '275px'
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      align: 'center',
    },
    noCourses: {
      display: 'grid'
    }, 
    icon: {
      marginRight: '10px'
    }
  }

  return (
      <>
        <Row>
          {confirmation !==  'false' ? 
            <Notification variant='success' title='Success!' description='Your purchase was successful' />
            :
            <></>
          }
        </Row>
        <Row>
          <Col style={styles.title}>
            <h2>Your Courses</h2>
          </Col>
        </Row>
        <hr/>

        
      {!loading ?
        <Row xs={1} md={3} >
          { courses && courses.length > 0 ? 
            courses.map((course) => {
              
              return (
                <Col className='md-3' align="center" key={course.id}>
                  <Card key={course.id}>
                    <Nav.Link onClick={() => goTo(`/mycourse/${course.id}/`)}>
                      <Card.Img variant='top' src={course.image_url} className='cardImage' style={styles.cardImage} />
                      <Card.Body>
                        <Card.Title>{course.name}</Card.Title>
                      </Card.Body>
                      <Card.Footer>
                        <Card.Text>Go to course</Card.Text>
                      </Card.Footer>
                    </Nav.Link>
                  </Card>
                </Col>
              )
            })
            :
            <NoProduct type='course'/>
          }
        </Row>
      :
      <LoadingSpinner md={12} offset={0} />
      }
      </>
  )
}
