import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import EBookService from '../../services/EBookService'
import EBookCard from '../../components/product/EBookCard'
import LoadingSpinner from '../../components/store/LoadingSpinner'

function EBooks() {
  const [ebooks, setEBooks] = useState(null);
  const { getEBooks } = EBookService();
  
  useEffect(() => {
    function loadEBooks() {
      return getEBooks().then(data  => {
        if (process.env.ENV_TYPE === 'development') {
          console.log('response', data );
        }
        setEBooks(data);
        return data;
      }).catch(error => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(error);
        }
      });
    };

    loadEBooks();
  }, []);

  if (process.env.ENV_TYPE === 'development') {
    console.log('Ebooks:  ', ebooks);
  }

  const styles = {
    title: {
      marginBottom: '10px',
    }
  }
  
  return (
    <>
      <h1 styles={styles.title} align="center" className="p-3">E-Books</h1>
      <hr/>
        { ebooks != null ?
          <Row xs={1} md={3} className="g-4">
            {ebooks.map((product) => (
              <Col align="center" key={product.id}>
                <EBookCard ebook={product} />
              </Col>
            ))}
          </Row>
          :
          <LoadingSpinner md={12} offset={0} />
        }
    </>
  )
}
    
export default EBooks;