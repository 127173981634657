import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/Navbar';
import { Container } from 'react-bootstrap';
import { Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from './context/UserContext';
import Landing from './pages/Landing';
import Courses from './pages/store/Courses';
import EBooks from './pages/store/EBooks';
import CourseDetail from './pages/store/CourseDetail';
import EbookDetail from './pages/store/EBookDetail';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import MyCourse from './pages/store/MyCourse';
import MyCourses from './pages/store/MyCourses';
import MyEBook from './pages/store/MyEBook';
import MyEBooks from './pages/store/MyEBooks';
import Contact from './pages/Contact';
import Recover from './pages/auth/Recover';
import Footer from './components/Footer';
import MyAccount from './pages/user/MyAccount';
import FAQs from './pages/FAQs';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    const loggedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    // console.log('logged user: ', loggedUser);

    if (loggedUser) {
      setUser(loggedUser);
    }
  }, []);

  const styles = {
    container: {
      marginTop: '40px',
      minHeight: '95vh'
    }
  }

  return (
    // <CartProvider>
    <UserContext.Provider user={user}>
      <NavbarComponent />
      <Container style={styles.container}>
        <Routes>
          <Route index element={<Landing />} />
          {/* Session Required */}
          {/* <Route path="mycourses/*" element={ user ? (<MyCourses />) : (<Navigate replace to='/' />) } /> */}
          <Route path="mycourses/*" element={<MyCourses />} />
          <Route path="mycourse/:productId" element={ user ? (<MyCourse />) : (<Navigate replace to='/' />) } />
          <Route path="myebooks/*" element={<MyEBooks />}/>
          <Route path="myebook/:productId" element={ user ? (<MyEBook />) : (<Navigate replace to='/' />) } />
          <Route path="myaccount" element={ user ? (<MyAccount />) : (<Navigate replace to='/' />) } />
          {/* NO Session Required */}
          <Route path="signin" element={ user ? (<Navigate replace to='/' />) : (<SignIn />) } />
          <Route path="signup" element={ user ? (<Navigate replace to='/' />) : (<SignUp />) } />
          <Route path="recover" element={ user ? (<Navigate replace to='/' />) : (<Recover />) } />
          
          <Route path="contact" element={<Contact />} />
          <Route path="faqs" element={<FAQs />} />
          <Route path="courses" element={<Courses />} />
          <Route path="course_detail/:productId" element={<CourseDetail />} />
          <Route path="ebooks" element={<EBooks />} />
          <Route path="ebook_detail/:productId" element={<EbookDetail />} />
          <Route path="terms" element={<TermsAndConditions />} />
          {/* Fallback route */}
          <Route path='*' element={<Navigate replace to='/'/>}/>
        </Routes>
      </Container>
      <Footer /> 
    </UserContext.Provider>
    // </CartProvider>
  );
}

export default App;
