import React, { useState, useEffect }  from 'react'
import CourseService from '../../services/CourseService'
import EBookService from '../../services/EBookService'
import { useParams } from 'react-router-dom'
import Helpers from '../../components/store/Helpers'
import { Col, Row, ListGroup, Button } from 'react-bootstrap'
import LoadingSpinner from '../../components/store/LoadingSpinner'

function MyCourse() {
  const id = useParams().productId
  const [course, setCourse] = useState(null)
  const [ebooks, setEbooks] = useState(null)
  const [videos, setVideos] = useState(null)
  const [index, setIndex] = useState(0)
  const { getCourseVideos, getCourse } = CourseService()
  const { getCourseEBooks } = EBookService()
  const { goTo } = Helpers()

  useEffect(() => {
  
    getCourse(id).then((response) => {
      setCourse(response)
      if (process.env.ENV_TYPE === 'development') {
        console.log(response)
      }
      
    }).catch(error => {
      if (process.env.ENV_TYPE === 'development') {
        console.log(error)
        goTo('/')
      }
    })

    getCourseVideos(id).then((response) => {
      if (process.env.ENV_TYPE === 'development') {
        console.log('getCourseVideos response: ', response)
      }

      setVideos(response)
      setIndex(response[0])

      return response
    }).catch((error) => {
      if (process.env.ENV_TYPE === 'development') {
        console.log(error)
        goTo('/')
      }
    })

    getCourseEBooks(id).then((response) => {
      // console.log('ebooks:',  response)
      if (response && !response.message ) {
        setEbooks(response)
      }
    }).catch(e => {
      console.log(e)
    })
  }, [])

  function openPDF(url) {
    window.open(url, '_blank', 'noreferrer')
  }

  const styles = {
    title: {
      marginBottom: '20px',
    },
    list: {
      marginBottom: '20px',
    },
    listItem: {
      borderColor: '#d3a1ff',
    },
    listItemActive: {
      backgroundColor: '#d3a1ff',
      borderColor: 'darkviolet',
      color: 'darkviolet',
    },
    ebookTitle: {
      textAlign: 'center'
    },
    videoPlayer: {
      width: '100%',
      height: window.innerWidth < 600 ? window.innerWidth < 300 ? '300px' : '400px' : '600px'
    }
  }

  const longDesc = course ? 
    course.long_description ? 
      course.long_description.split('\n') 
      : ''
    :
    null

  return (
    <>
      <Row align='center' style={styles.title}>
        <h1>{course != null ? course.name : ''}</h1>
      </Row>
      <hr/>
      <Row>
        <Col xs={12} md={12} lg={2} style={styles.list}>
          <ListGroup>
            {
              videos != null && videos !== undefined ?
                videos.map((video) => (
                  <ListGroup.Item action as="li" onClick={() => setIndex(video)} key={video.id} className={video.id === index.id ? 'active' : ''} style={video.id === index.id ? styles.listItemActive : styles.listItem}> 
                    <h5> { video.name } </h5>
                    <p> { video.short_description } </p>
                  </ListGroup.Item>
                ))
                :
                <LoadingSpinner md={12} offset={0} />
            }
          </ListGroup>
        </Col>
        <Col md={12} xs={12} lg={10}>
          {
            index != null ?
            // <video src={index.url} controls muted height={500} width={1000}>
            //   <source  type="video/mp4">
            //   </source>
            // </video>
            
            <iframe  style={styles.videoPlayer} /*width="1000" height="500"*/ src={index.url} title="YouTube video player" allow="accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture" allowFullScreen/>
            :
            'There was  an error loading this course. Try again later'
          }
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col>
          <h3>About this Course</h3>
          <p>{longDesc}</p>
        </Col>
        <Col>
          <h2 style={styles.ebookTitle}>EBooks for this course</h2>  <hr/>
          { ebooks !== null && ebooks.length > 0 ?
            <>
              <ListGroup>
                { ebooks.map((product) => (
                  <ListGroup.Item action key={product.id} as="li" className="d-flex justify-content-between"> 
                    <p>{ product.name } </p>
                    <Button onClick={ () => openPDF(product.url) }> Download PDF </Button>
                  </ListGroup.Item>
                  ))
                }
              </ListGroup>
            </> 
            :
            <>
              <p>
                <b>*</b> There are no E-Books for this course
              </p>
            </>
          }
        </Col>
      </Row>
    </>
  )
}

export default MyCourse