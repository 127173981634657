import React, { useState } from 'react'
import { Dropdown, Button } from 'react-bootstrap'
import Helpers from '../store/Helpers'
import UserService from '../../services/UserService'

function AuthIcon() {
  const [user, setUser] = useState(null)
  const { goTo } = Helpers()
  const { logout } = UserService()
  
  const loggedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  if (process.env.ENV_TYPE === 'development') {
   console.log(user)
  }

  function handleLogout() {
    setUser(null)
    logout()
    window.location.reload(false)
  }

  if (user != null && loggedUser == null) { 
    localStorage.setItem('user', JSON.stringify(user))
  }
  if (loggedUser != null && user == null) { 
    setUser(loggedUser)
  }

  return user ? 
    <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic">
        My Account
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => goTo('myaccount/')}>Account</Dropdown.Item>
        <Dropdown.Item onClick={() => goTo('mycourses/')}>My Courses</Dropdown.Item>
        <Dropdown.Item onClick={() => goTo('myebooks/')}>My E-Books</Dropdown.Item>
        <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    :
    <Button onClick={() => goTo('signin')}>
      Sign In / Sign Up
    </Button>
}

export default AuthIcon
