import React from 'react'
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap'
import Helpers from './store/Helpers'
import { FaWhatsapp, FaRegEnvelope } from 'react-icons/fa'

export default function Footer() {
  const { goTo } = Helpers()

  const styles = {
    container: {
      height: '100px',
      width: 'auto',
    },
    footer: {
      minHeight: '10vh',
      padding: '5vh',
      marginTop: '10vh',
      // borderTop: '1px groove black'
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    firstRow: {
      marginBottom: '3vh'
    },
    title: {
      //textAlign: 'center',
      fontWeight: 'bold',
      display: 'block'
    },
    subtitle: {
      fontWeight: 'bold',
      display: 'block'
    },
    text: {
      fontSize: '0.9em',
      textAlign: 'center',
      //fontStyle: 'italic'
    },
    link: {
      marginTop: '1vh',
      textAlign: 'center',
      color: 'rgb(153, 50, 204)',
      fontWeight: 'bold',
    },
    list: {
      listStyleType: 'none'
    },
    contact: {
      textAlign: 'center',
      fontWeight: 'bold',
      // float: 'right',
      display: 'block'
    },
    listElement: {
      textAlign: 'center',
      // display: 'inline-block'
    },
    contactText: {
      paddingLeft: '2vh',
      textAlign: 'left'
    },
    navItem: {
      textAlign: 'left',
      display: 'grid',
      width: 'max-content',
    }
  }

  return (
    <>
      <footer style={styles.footer}>
        <Container>
          <Row>
            <Col xs={{span:6}} md={{span:3}} lg={{span:3}}>
              <Nav.Item>
                <Nav.Link href="/terms" style={styles.link}>TERMS & CONDITIONS</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <span style={styles.text}>© 2021 Copyright: blockchaininvestorhub</span>
              </Nav.Item>
            </Col>
            <Col xs={{span:6, offset:0}} md={{span:3, offset:6}} lg={{span:3, offset:6}}>
              <Nav.Item style={styles.navItem}>
                <span style={styles.contact}>Contact</span>
                <span style={styles.listElement}>
                  <FaRegEnvelope icon="fa-regular fa-regenvelope"/>
                  <span style={styles.contactText}>inquiries@blockchaininvestorhub.com</span>
                </span>
                <span style={styles.listElement}>
                  <FaWhatsapp  icon="fa-regular fa-whatsapp"/>
                  <span style={styles.contactText}>+1 (307) 240-2133</span>
                </span>
              </Nav.Item>
            </Col>
          </Row>
        </Container>


        {/* <Row>
          <Col xs={{span:6}} md={{span:3}} lg={{span:3}}>
            <Nav.Item>
            <span style={styles.title}>Terms and Conditions</span>
            <span>This contract ("Contract") sets forth the terms and conditions between Make up LLC (hereinafter referred to as the "Site") and the users  <b>...</b></span>
            <Nav.Link href="/terms" style={styles.link}>[ SEE MORE ]</Nav.Link>
            </Nav.Item>
          </Col>
          <Col xs={{span:6, offset:0}} md={{span:3, offset:6}} lg={{span:3, offset:6}}>
            <Nav.Item style={styles.navItem}>
              <span style={styles.contact}>Contact</span>
              <span style={styles.listElement}>
                <FaRegEnvelope icon="fa-regular fa-regenvelope"/>
                <span style={styles.contactText}>inquiries@blockchaininvestorhub.com</span>
              </span>
              <span style={styles.listElement}>
                <FaWhatsapp  icon="fa-regular fa-whatsapp"/>
                <span style={styles.contactText}>+1 (307) 240-2133</span>
              </span>
            </Nav.Item>
          </Col>
        </Row> */}


        {/* <Nav className="justify-content-center" activeKey="/home">
          <Row>
            <Col>
              <Nav.Item>Legales</Nav.Item> <br/>
              <Nav.Link href="/terms">Terminos y Condiciones</Nav.Link>
              <Nav.Link href="/contact">Contacto</Nav.Link>
            </Col>
            <Col>
              <Nav.Item>Contacto</Nav.Item>
            </Col>
          </Row>
        </Nav> */}


        {/* <Row style={styles.firstRow}>
          <Col xs={{span:3, offset:4}} md={{span:3, offset:4}} lg={{span:3, offset:4}}>
            <span style={styles.title}>Terms and Conditions - Blockchain Investor Hub</span>
            <span>This contract ("Contract") sets forth the terms and conditions between Make up LLC (hereinafter referred to as the "Site") and the users (hereinafter referred to as "Users" or "You") who wish to access and use the services of the online blockchain course website (hereinafter referred to as the "Services"). By using the Site's Services, You agree to be bound by the terms and conditions set forth in this Contract.</span>
          </Col>
        </Row>
        <Row>
          <Col xs={3} md={3} lg={3}>
            <span style={styles.subtitle}>Services</span>
            <span style={styles.text}>The Site provides an online platform that allows Users to access and purchase blockchain courses. These courses are created by artificial intelligence algorithms (hereinafter referred to as "AI Instructors") and cover various topics related to blockchain. The Site will facilitate the purchasing process, provide technical support, and ensure the availability of the purchased courses.</span>
          </Col>
          <Col xs={3} md={3} lg={3}>
            <span style={styles.subtitle}>Services</span>
            <span style={styles.text}>The Site provides an online platform that allows Users to access and purchase blockchain courses. These courses are created by artificial intelligence algorithms (hereinafter referred to as "AI Instructors") and cover various topics related to blockchain. The Site will facilitate the purchasing process, provide technical support, and ensure the availability of the purchased courses.</span>
          </Col>
          <Col xs={3} md={3} lg={3}>
            <span style={styles.subtitle}>Services</span>
            <span style={styles.text}>The Site provides an online platform that allows Users to access and purchase blockchain courses. These courses are created by artificial intelligence algorithms (hereinafter referred to as "AI Instructors") and cover various topics related to blockchain. The Site will facilitate the purchasing process, provide technical support, and ensure the availability of the purchased courses.</span>
          </Col>
          <Col xs={3} md={3} lg={3}>
            <span style={styles.subtitle}>Services</span>
            <span style={styles.text}>The Site provides an online platform that allows Users to access and purchase blockchain courses. These courses are created by artificial intelligence algorithms (hereinafter referred to as "AI Instructors") and cover various topics related to blockchain. The Site will facilitate the purchasing process, provide technical support, and ensure the availability of the purchased courses.</span>
          </Col>
        </Row> */}
      </footer>
    </>
  )
}
