import React from 'react'
import SignInForm from '../../components/auth/SignInForm'
import { Container } from 'react-bootstrap'

function SignIn() {
  return (
    <Container>
      <SignInForm />
    </Container>
  )
};

export default SignIn;