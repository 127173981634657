import React from 'react'
import Helpers from '../store/Helpers'
import { Col, Button } from 'react-bootstrap'
import { FaRegSadTear, FaInfoCircle } from 'react-icons/fa'

export default function NoProduct(props) {

  const type = props.type
  const { goTo } = Helpers()

  const styles = {
    noCourses: {
      display: 'grid'
    }, 
    icon: {
      marginRight: '10px'
    }
  }
  
  return (
    <Col md={{md: 4, offset: 4}} style={styles.noCourses}>
      <h3>
        <FaRegSadTear style={styles.icon}/>
        You don't have any {type}s yet!
      </h3>
      <hr/>
      <p><FaInfoCircle />  If you recently bought a product, it might take a few moments to process in our system</p>
      <Button  color='' className='' onClick={() => goTo(`/${type}s/`)}>
        Go to all {type}s
      </Button>
    </Col>
  )
}
