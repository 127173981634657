import React, { useState, useEffect } from 'react'
import { Col, Row, Card, Nav } from 'react-bootstrap'
import EBookService from '../../services/EBookService'
import { useSearchParams } from 'react-router-dom'
import Helpers from '../../components/store/Helpers'
import NoProduct from '../../components/store/NoProduct'
import Notification from '../../components/store/Notification'
import LoadingSpinner from '../../components/store/LoadingSpinner'

export default function MyEBooks() {
  const [ebooks, setEBooks] = useState([])
  const [loading, setLoading] = useState(true)
  const { getUserEBooks } = EBookService()
  const { goTo } = Helpers()
  const [searchParams, setSearchParams] = useSearchParams({success: false})
  const confirmation = searchParams.get('success')
  
  useEffect(() => {
    function loadEBooks() {
      return getUserEBooks().then(data  => {
        if (process.env.ENV_TYPE === 'development') {
          console.log('response', data )
        }
        if (!data.detail) {
          setEBooks(data)
        }

        return data
      }).catch(error => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(error)
        }
        goTo('/')
      })
    }

    loadEBooks()
    setLoading(false)
    if (process.env.ENV_TYPE === 'development') {
      console.log('Ebooks: ', ebooks)
    }
  }, [])

  function openPDF(url) {
    window.open(url, '_blank', 'noreferrer')
  }

  const styles = {
    listItem: {
      alignItems: 'horizontal',
    },
    btn: {

    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      align: 'center',
    },
    a: {
      textDecoration: 'none',
      color: 'white',
    },
    'a:hover': {
      color: 'white'
    }
  }

  return (
    <>
      <Row>
        {confirmation !==  'false' ? 
          <Notification variant='success' title='Success!' description='Your purchase was successful' />
          :
          <></>
        }
      </Row>
      
      <Row>
        <Col style={styles.title}>
          <h2>Your E-Books</h2>
        </Col>
      </Row>
      <hr/>
    
    {!loading
      ?
      <Row xs={1} md={3} >
        {ebooks && ebooks.length > 0 ?
         ebooks.map((ebook) => {
              
              return (
                <Col className='md-3' align="center" key={ebook.id}>
                  <Card key={ebook.id}>
                    <Nav.Link onClick={() => openPDF(ebook.url)}>
                      <Card.Img variant='top' src={ebook.image_url} className='cardImage' style={styles.cardImage} />
                      <Card.Body>
                        <Card.Title>{ebook.name}</Card.Title>
                      </Card.Body>
                      <Card.Footer>
                        <Card.Text>Go to ebook</Card.Text>
                      </Card.Footer>
                    </Nav.Link>
                  </Card>
                </Col>
              )
            })

  
          :
          <NoProduct type='ebook'/>
        }
      </Row>
      :
      <LoadingSpinner md={12} offset={0} />
    }
    </>
  )
}
