import React, { useState, useRef } from 'react'
import { Nav, Card, Button, Form, FloatingLabel, Row, Col } from 'react-bootstrap'
import UserService from '../../services/UserService'
import img1 from '../../assets/imgs/img43.jpg'

function SignUpForm() {

  const { register, login } = UserService()
  const username = useRef(null)
  const password = useRef(null)
  const password_check = useRef(null)
  const [error, setError] = useState('')
  const [user, setUser] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (password.current.value !== password_check.current.value) {
      setError('555')
    
    } else {

      if (process.env.ENV_TYPE === 'development') {
        console.log('No errors. Sending: ', username.current.value, password.current.value) 
      }

      register(username.current.value, password.current.value).then((response) => {
          if (process.env.ENV_TYPE === 'development') {
            console.log('register form: ', response)
          }

          if (response.status_code === 200) {
            if (process.env.ENV_TYPE === 'development') {
              console.log('User created sucesfully. Login in with credentials -> user: ', username.current.value, ' password: ' , password.current.value)
            }
            
            login(
              username.current.value, 
              password.current.value
            ).then((response) => {
              if (process.env.ENV_TYPE === 'development') {
                console.log('User logged in sucesfully. Token: ', response.token)
              }
              const stored_user = {
                name: username.current.value,
                token: response.token,
              }
    
              localStorage.setItem('user', JSON.stringify(stored_user))
              setUser(stored_user)
              window.location.reload(false)
            }).catch((error) => {
              if (process.env.ENV_TYPE === 'development') {
                console.log(error)
              }
              setError('500')
            })

          } else {
            setError('500')
          }
        }
      ).catch((error) => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(JSON.stringify(error))
        }
      })
    }
  }

  var styles = {
    inputContainer: {
      marginBottom: '10px'
    },
    signinLabel: {
      marginTop: '5px'
    }
  }

  return (
    <Card>
      <Row>
        <Col>
          <Card.Body className='d-flex flex-column'>
            <Card.Title>Sign Up!</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='email' label='Email' className=''>
                  <Form.Control type='email' placeholder='Enter your email' ref={username}/>
                </FloatingLabel>
                <Form.Text className='text-muted error-email'> {error === '500' ? 'Revisa este campo' : ''} </Form.Text>
              </Form.Group>
              {/* <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='fullname' label='Nombre y Apellido' className=''>
                  <Form.Control type='name' placeholder='Ingresa tu nombre completo' ref={fullName}/>
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='password' label='Password' className=''>
                  <Form.Control type='password' placeholder='Enter your password' ref={password}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='passwordcheck' label='Password' className=''>
                  <Form.Control type='password' placeholder='Repeat password' ref={password_check}/>
                </FloatingLabel>
                <Form.Text className='text-muted error-password'> {error === '555' ? 'Las contraseñas no coinciden' : ''} </Form.Text>
              </Form.Group>
              <Form.Group>
                <Button variant='primary' type='submit' style={styles.button}>Sign Up!</Button>
              </Form.Group>
            </Form>
            <hr/>
            <Nav.Link href='signin' style={styles.signinLabel}>
              <p> Already a member? Sign in here! </p>
            </Nav.Link>
          </Card.Body>
          
        </Col>
        <Col>
          <Card.Img src={img1} />
        </Col>
      </Row>
    </Card>
  )
}

export default SignUpForm