import React, { useState, useRef } from 'react'
import { Nav, Card, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap'
import Helpers from '../store/Helpers'
import UserService from '../../services/UserService'
import img1 from '../../assets/imgs/img99.jpg'

function SignInForm() {
  // const { user, setUser } = useContext(UserContext)
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const username = useRef(null)
  const password = useRef(null)
  const { goTo } = Helpers()
  const { login } = UserService()

  const handleSubmit = (event) => {
    event.preventDefault()

    login(username.current.value, password.current.value).then((response) => {
      if (process.env.ENV_TYPE === 'development') {
        console.log('Token: ', response.token)
      }
      if (response.token) {
        const stored_user = {
          name: username.current.value,
          token: response.token,
        }

        localStorage.setItem('user', JSON.stringify(stored_user))
        setUser(stored_user)
        if (process.env.ENV_TYPE === 'development') {
          console.log('Login ok. Stores locally user: ', stored_user) 
        }
        window.location.reload(false)
      } else {
        setError('Usuario o contraseña incorrectos')
      }
    }).catch((error) => {
      setError('Hubo un error inesperado')
      if (process.env.ENV_TYPE === 'development') {
        console.log(error) 
      }    
    })
  }

  var styles = {
    label: {
      marginTop: '5px'
    }, 
    button: {
      marginTop: '5px',
      float: 'right',
    },
    inputContainer: {
      marginBottom: '10px'
    },
    goToRegister: {
      float: 'center',
    },
    gif: {
      maxWidth: '100%'
    }
  }

  return (
    <Card>
      <Row>
        <Col>
          <Card.Img src={img1}/>
          {/* Agregar Foto */}

          {/* <video src={window.innerWidth > 600 ? img1 : img2} muted height='auto' width='auto' autoplay='true' loop style={styles.gif}>
            <source  type="video/mp4" />
          </video> */}
        </Col>
        <Col>
          <Card.Body className='d-flex flex-column'>
            <Card.Title>
              Sign In
            </Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='floatingInput1' label='Email' className=''>
                  <Form.Control ref={username} type='email' placeholder='Enter your email' />
                </FloatingLabel>
                <Form.Text className='text-muted error-email'>{error}</Form.Text>
              </Form.Group>
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='floatingInput2' label='Password' className=''>
                  <Form.Control ref={password} type='password' placeholder='Enter your password' />
                </FloatingLabel>
              </Form.Group>
              <Form.Group>
                <Button variant='primary' type='submit' style={styles.button}>
                  Sign In
                </Button>
              </Form.Group>
            </Form>
            <hr/>
            <Row>
              <Col>
                <Nav.Link onClick={() => goTo('/recover')}>
                  <p style={styles.goToRegister}> Forgot my password </p>
                </Nav.Link>
              </Col>
              <Col>
                <Nav.Link onClick={() => goTo('/signup')}>
                  <p style={styles.goToRegister}> Not a member? Sign up! </p>
                </Nav.Link>
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  )
}

export default SignInForm