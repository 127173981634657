import Helpers from "../components/store/Helpers"

export default function CourseService() {
  const api_url = process.env.REACT_APP_API_URL_PROD
  const user = localStorage.getItem('user')
  const { goTo } = Helpers()
  let token = null

  async function getCourses() {
    const path = 'course/'
    const url = api_url+path

    return fetch(url).then((response) => {
        return response.json()
      }).catch(error => {
        if (process.env.ENV_TYPE === 'development') {
          console.log('Error:  ', error)
        }
        return false
      })
  }

  async function getCourse(id) {
    const path = `course/${id}/`
    const url = api_url+path

    return fetch(url)
      .then((response) => {
        return response
      }).then((data) => {
        return data.json()
      }).catch(error => {
        return error
      })
  }

  async function getUserCourses() {
    const path = 'user-course/'
    const url = api_url+path
    if (process.env.ENV_TYPE === 'development') {
      console.log('User: ', localStorage.getItem('user'))
    }
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      return response.json()
    })
  }

  async function getCourseVideos(id) {
    const path = `course-video/${id}/`
    const url = api_url+path
    // console.log('User: ', localStorage.getItem('user').token, localStorage.getItem('user').username)
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')
    if (process.env.ENV_TYPE === 'development') {
      console.log(`Fetching course with id ${id} from '${url}' with token ${token}`)
    }

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      return response.json()
    })
  }

  async function getCourseVideo(id) {
    const path = `video/${id}/`
    const url = api_url+path
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      return response.json()
    })
  }

  async function hasCourse(id) {
    const path = `user_asset/course/${id}/`
    const url = api_url+path
    user != null ? token = JSON.parse(localStorage.getItem('user')).token : goTo('/')

    return fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    }).then((response) => {
      //console.log(response.json())
      return response.json()
    }).catch((e) => {
      console.log(e)
      return null
    })
  }

  return { getCourses, getUserCourses, getCourse, getCourseVideos, getCourseVideo, hasCourse }
}
