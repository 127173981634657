import React from 'react'

export default function TermsAndConditions() {
  const styles = {
    title: {
      textAlign: 'center'
    }
  }

  return (
    <>
      <h2 style={styles.title}>Terms and Conditions - Blockchain Investor Hub</h2>
      <hr/>
      <span>This contract ("Contract") sets forth the terms and conditions between Make up LLC (hereinafter referred to as the "Site") and the users (hereinafter referred to as "Users" or "You") who wish to access and use the services of the online blockchain course website (hereinafter referred to as the "Services"). By using the Site's Services, You agree to be bound by the terms and conditions set forth in this Contract.</span>
      <hr/>

      <h3>Services</h3>
      <span>The Site provides an online platform that allows Users to access and purchase blockchain courses. These courses are created by artificial intelligence algorithms (hereinafter referred to as "AI Instructors") and cover various topics related to blockchain. The Site will facilitate the purchasing process, provide technical support, and ensure the availability of the purchased courses.</span>
      <hr/>
      <br/>

      <h3>Registration and User Account</h3>
      <span>To use the Services, You must register on the Site and create a user account. You agree to provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your login information and will be held responsible for all activities that occur under your account.</span>
      <hr/>
      <br/>

      <h3>Purchase of Courses</h3>
      <span>By purchasing a course through the Site, You agree to pay the specified fees associated with that course. The payment shall be made through the Site's designated payment gateway. Once the payment is successfully processed, You will gain access to the purchased course materials.</span>
      <hr/>
      <br/>

      <h3>Intellectual Property</h3>
      <span>The Site retains all intellectual property rights to the course materials provided through the Services. You agree not to copy, distribute, or reproduce any course materials without obtaining prior written consent from the Site.</span>
      <hr/>
      <br/>

      <h3>Disclaimer of Warranty</h3>
      <span>The Site does not guarantee the accuracy, completeness, or reliability of the course materials created by the AI Instructors. The Site is not responsible for any errors, omissions, or inaccuracies in the course content. The Services are provided on an "as-is" basis, without any warranties or representations, express or implied.</span>
      <hr/>
      <br/>

      <h3>Limitation of Liability</h3>
      <span>In no event shall the Site be liable for any indirect, incidental, consequential, or special damages arising out of or in connection with the use of the Services. The total liability of the Site, whether in contract, tort, or otherwise, shall not exceed the amount paid by You for the course giving rise to the claim.</span>
      <hr/>
      <br/>

      <h3>Termination</h3>
      <span>The Site reserves the right to terminate or suspend your access to the Services at any time, without prior notice, if You violate any provisions of this Contract. Upon termination, You will no longer have access to the purchased courses and any remaining fees shall not be refunded.</span>
      <hr/>
      <br/>

      <h3>Governing Law and Jurisdiction</h3>
      <span>This Contract shall be governed by and construed in accordance with the laws of Wyoming, USA. Any disputes arising out of or in connection with this Contract shall be subject to the exclusive jurisdiction of the courts of Wyoming, USA.</span> <br/>
      <span>By using the Services of the Site, You acknowledge that You have read, understood, and agreed to the terms and conditions of this Contract.</span>
    </>
  )
}
