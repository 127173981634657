import React, { useState, useRef } from 'react'
import { Row, Col, Card, Button, Form, FloatingLabel, Spinner } from 'react-bootstrap'
import UserService from '../../services/UserService'

export default function Recover() {

  const { recover } = UserService()
  const [ msg, setMsg ] = useState('An E-Mail will be send to your registered address')
  const [ isLoading, setLoading ] = useState(false)
  const username = useRef(null)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (username.current.value ===  '') {
      setMsg('Please complete this field')
      setLoading(false)
      return false
    }

    recover(username.current.value).then(
      (response) => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(response)
        }
        setMsg('Your new password has been sent. Check your mail!')
        setLoading(false)
      }
    ).catch((error) => {
      if (process.env.ENV_TYPE === 'development') {
        console.log(error)
      }
    })
  }

  var styles = {
    label: {
      marginTop: '5px'
    }, 
    button: {
      marginTop: '5px',
      float: 'right',
    },
    inputContainer: {
      marginBottom: '10px'
    },
    goToRegister: {
      float: 'center',
    },
  }

  return (
    <Card>
      <Row>
        <Col>
          <Card.Body className='d-flex flex-column'>
            {/* <Card.Title>
              Ingresar
            </Card.Title> */}
            <Form onSubmit={handleSubmit}>
              <Form.Group style={styles.inputContainer}>
                <FloatingLabel controlId='floatingInput1' label='Email' className=''>
                  <Form.Control ref={username} type='email' placeholder='Ingresa tu email' />
                </FloatingLabel>
                <Form.Text className='text-muted error-email'>{msg}</Form.Text>
              </Form.Group>
              <hr/>
              <Form.Group>
                <Button variant='primary' type='submit' style={styles.button} onClick={() => setLoading(true)}>
                  {isLoading ?
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    'Send E-Mail'
                }
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Col>
        <Col>
          {/* Agregar Foto */}
        </Col>
      </Row>
    </Card>
  )
}
