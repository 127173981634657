import React from 'react'
import { Col, Spinner } from 'react-bootstrap'

export default function LoadingSpinner(props) {

  const styles =  {
    spinner: {
      justifyContent: 'center',
      align: 'center',
      display: 'flex'
    }
  }

  return (
    // <Col md={{offset: 4, md: 8}} style={styles.spinner}>
    <Col md={{offset: props.offset, md: props.md}} style={styles.spinner}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Col>
  )
}
