export default function PaymentService() {
  const api_url = process.env.REACT_APP_API_URL_PROD
  const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null

  async function checkout(id, type)  {
    const path = 'create-checkout-session/'
    const data  = {
      asset_type: type,
      asset_id: id,
    }

    // console.log(`--  CHECKOUT  --  Sending to path '${api_url+path}' data: ${JSON.stringify(data)} `)

    return fetch(api_url+path, {
      method: 'POST',
      headers: {
        'Content-Type':  'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify(data)
    }).then((response) => {
      return response.ok ? response.json() : false
      // console.log(response);
      // return response.ok ? response.json() : response.ok
      
      // return response.json().then(json => Promise.reject(json))
    })/*.then(({ url }) => {
      window.location = url
    })*/.catch(e => {
      console.log(e)  
      return false
    })
  }

  async function checkoutTest(id)  {
    const path = 'checkout/'

    return fetch(api_url+path, {
      method: 'POST',
      headers: {
        'Content-Type':  'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify({
        items: [
          {
            id: id,
            quantity: 1,
          }
        ]
      })
    }).then((response) => {
      if (response.ok) {
        localStorage.setItem('purchaseConfirmation', true)
        return response.json()
      }
      return response.json().then(json => Promise.reject(json))
    }).then(({ url }) => {
      window.location = url
    }).catch(e => {
      if (process.env.ENV_TYPE === 'development') {
        console.log(e)
    }})
  }

  return { checkout, checkoutTest }
}
