import React from 'react'
import SignUpForm from '../../components/auth/SignUpForm'
import { Container } from 'react-bootstrap'

function SignIn() {
  return (
    <Container>
      <SignUpForm />
    </Container>
  )
};

export default SignIn;