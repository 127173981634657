import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col/*, ListGroup*/ } from 'react-bootstrap'
import CourseService from '../../services/CourseService'
// import EBookService from '../../services/EBookService'
import Course from '../../components/product/Course'
import LoadingSpinner from '../../components/store/LoadingSpinner'
import Helpers from '../../components/store/Helpers'

function CourseDetail() {
  const id = useParams().productId
  const [course, setCourse] = useState(null)
  const [loading, setLoading] = useState(true)
  // const [ebooks, setEbooks] = useState(null)
  const { getCourse } = CourseService()
  // const { getCourseEBooks } = EBookService()
  const { goTo } = Helpers()
  
  useEffect(() => {

    function loadCourse(id) {
      // console.log('course id: ', id)
      return getCourse(id).then(data  => {
        // console.log('data', data)
        setCourse(data)
        return data;
      }).catch(error => {
        if (process.env.ENV_TYPE === 'development') {
          console.log(error)
        }
        goTo('/')
      });
    };

    /*function loadEBooks(course_id) {
      getCourseEBooks(course_id).then((response) => {
        console.log('ebooks:',  response)
        if (response && !response.message ) {
          setEbooks(response)
        }
      }).catch(e => {
        console.log(e)
        goTo('/')
      })
    }*/

    loadCourse(id)
    // loadEBooks(id)
    setLoading(false)
  }, []);

  const styles = {
    title: {
      marginBottom: '10px',
    },
    longDesc: {
      minHeight: '300px'
    },
    rowContainer: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    ebookList: {
      //justifyContent: 'flex-start'
    },
    ebookName: {
      marginLeft: '10px',
      width: '100%'
    },
    ebookTitle: {
      matginBottom: '30px'
    }
  }

  const longDesc = course ? course.long_description ? course.long_description.split('\n') : '' : ''
  let counter = 0

  return (
    !loading && course ?
      <>
        {/* <Row>
          <h1 style={styles.title} align="center" className="p-3">{course.name}</h1>
        </Row> */}
        <hr/>
        <Row style={styles.rowContainer}>
          <Col className='md-8'>
            <Row style={styles.longDesc}>
              { longDesc.map((row) => {
                  counter++
                  return (
                    <Row key={counter}>
                      <p>{row}</p>
                    </Row>
                  )
                })
              }
            </Row>
            {/* <hr/> */}
            <Row>
              {/* <h2 style={styles.ebookTitle}>This course comes with the following Ebooks</h2> */}
              { /*ebooks !== null && ebooks.length > 0 ?
                <ListGroup numbered  style={styles.ebookList} as='ol' >
                  { ebooks.map((product) => (
                    
                    <ListGroup.Item action key={product.id} as='li' className="d-flex justify-content-start"> 
                    <div className="ms-2 me-auto">
                      <p style={styles.ebookName} className="fw-bold">{product.name}</p>
                      <p>{product.short_description}</p>
                    </div>
                      
                      
                    </ListGroup.Item>
                    
                    ))
                  }
                </ListGroup>
                :
                <>
                  <p>
                    <b>*</b> There are no E-Books for this course
                  </p>
                </>
                */}
            </Row>
          </Col>
          <Col className='md-4'>
            <Course course={course} />
          </Col>
        </Row>
      </>
      :
      <LoadingSpinner md={12} offset={0} />
  );
}
  
export default CourseDetail;