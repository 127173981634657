import { useEffect, useState } from 'react'
import { Card, Button, Row, Col, Spinner } from 'react-bootstrap'
import PaymentService from '../../services/PaymentService'
import CourseService from '../../services/CourseService'
import Helpers from '../store/Helpers'
import Notification from '../store/Notification'

function Course(props) {
  const course = props.course;
  const { checkout } = PaymentService()
  const { hasCourse } = CourseService()
  const { goTo } = Helpers()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ userAsset, setUserAsset ] = useState(false)
  const [ error, setError ] = useState(false)
  const user = localStorage.getItem('user')

  
  useEffect(() => {
    if (user) {
      // console.log('course', course)
      hasCourse(course.id).then((response) => {
        // console.log(response);
        setUserAsset(response.message)
      })
    }
    // console.log('course', course)
    if (!course || course.detail === 'Not found.') {
      goTo('/courses/')
    }
  }, []);

  function goToCheckout(url)  {
    window.location = url
  }
  
  function proccessCheckout(id) {
    setIsLoading(true)
    
    checkout(id, 'Course').then(response => {
      if (response) {
        goToCheckout(response.url)
      }  else {
        setIsLoading(false)
        setError(true)
      }
    })

  }

  const styles = {
    subtitle: {
      justifyContent: 'right',
    },
    priceTag: {
      justifyContent: 'right',
    }
  }
  
  return (
    <Card>
      <Card.Header>
        <Card.Img variant='top' src={course.image_url} className='cardImage' />
      </Card.Header>
      <Card.Body>
        <Card.Title>{course.name}</Card.Title>
        <Card.Subtitle style={styles.priceTag} className=''>{`USD ${course.price}`}</Card.Subtitle>
        <hr/>
        <Card.Text style={styles.subtitle}>{course.short_description}</Card.Text>
        <Row>
          { error ?
            <Notification variant='danger' title='Oops!' description='There was an error trying to purchase that course. Plase try again later' /> :
            ''
          }
          { user ?
            userAsset ?
              <Button onClick={() => goTo(`/mycourse/${course.id}`)}>Go to course</Button>
              :
              <Button onClick={() => proccessCheckout(course.id)}>
                { isLoading ? 
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> :
                  'Purchase'
                }
              </Button>
              
            :
            <Button onClick={() => goTo('/signin')}>Purchase</Button>
          }
          <Col sm="6" />
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Course;